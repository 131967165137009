import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Layout } from "../components";
import sr from "../utils/sr";
import config from "../config";
import { Neko } from "neko-ts";

const StyledMiaSection = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 0;
  
  @media (max-width: 768px) {
    padding: 140px 25px;
  }

  .heart {
    color: var(--pink);
    font-size: 28px;
    margin: 0 5px;
    display: inline-block;
    animation: heartbeat 1.5s infinite;

    @keyframes heartbeat {
      0% {
        transform: scale(1);
      }
      25% {
        transform: scale(1.1);
      }
      50% {
        transform: scale(1);
      }
      75% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  h1 {
    margin: 0 0 30px;
    font-size: clamp(40px, 8vw, 60px);
    color: var(--lightest-slate);
    text-shadow: 3px 3px 0px var(--navy);
  }

  .subtitle {
    color: var(--pink);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    margin-bottom: 40px;
    letter-spacing: 1px;
  }

  p {
    margin: 20px 0;
    line-height: 1.8;
    color: var(--slate);
    font-size: var(--fz-lg);
  }

  .signature {
    margin-top: 80px;
    font-style: italic;
    font-family: var(--font-mono);
    text-align: right;
    color: var(--pink);
    font-size: var(--fz-lg);
  }

  .love-quote {
    margin: 50px 0;
    padding: 25px;
    border-left: 3px solid var(--pink);
    font-style: italic;
    color: var(--light-slate);
    background-color: rgba(100, 255, 218, 0.07);
    border-radius: 0 4px 4px 0;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateX(5px);
    }

    .author {
      margin-top: 15px;
      text-align: right;
      font-size: var(--fz-sm);
      color: var(--slate);
    }
  }
  
  .highlight {
    color: var(--pink);
    font-weight: 500;
  }
`;

type Props = {
  location: any;
};

const MiaPage = ({ location }: Props): JSX.Element => {
  const revealContainer = useRef<HTMLElement | null>(null);
  const nekoRef = useRef<Neko>();

  useEffect(() => {
    if (sr && revealContainer.current) {
      sr.reveal(revealContainer.current, config.srConfig());
    }
    
    if (!nekoRef.current) {
      nekoRef.current = new Neko({
        origin: {
          x: window.innerWidth - 100,
          y: 100,
        },
      });
    }

    return () => {
      if (nekoRef.current) {
        nekoRef.current.destroy?.();
      }
    };
  }, []);

  return (
    <Layout location={location}>
      <StyledMiaSection id="mia" ref={revealContainer}>
        <h1>
          Dear Mia <span className="heart">❤</span>
        </h1>
        <div className="subtitle">A LETTER FROM MY HEART TO YOURS</div>

        <p>
          From the moment you came into my
          life, you&apos;ve filled it with more joy, love, and warmth than I
          ever thought possible. This page is a small token of my appreciation
          for everything you are and everything you bring to my world.
        </p>

        <p>
          Your <span className="highlight">smile</span> brightens even my darkest days. Your <span className="highlight">kindness</span> inspires me
          to be better. Your <span className="highlight">strength</span> gives me courage. Your <span className="highlight">love</span> transforms
          ordinary moments into extraordinary memories.
        </p>

        <p>
          I cherish every second we spend together—whether we&apos;re exploring
          new places, laughing until our sides hurt, having deep conversations,
          or simply enjoying comfortable silence in each other&apos;s company.
        </p>
        <div className="love-quote">
          &ldquo;In all the world, there is no heart for me like yours. In all the
          world, there is no love for you like mine.&rdquo;
          <div className="author">— Maya Angelou</div>
        </div>

        <p>
          Thank you for your patience, your understanding, and your unwavering
          support. Thank you for believing in me, challenging me, and loving me
          exactly as I am.
        </p>

        <p>
          You are my best friend, my confidant, my favourite person. I&apos;m
          endlessly grateful that our paths crossed and our stories intertwined.
          Every day with you is a blessing that I treasure deeply.
        </p>

        <div className="signature">
          With all my love,
          <br />
          Forever yours,
          <br />
          Lachlan
        </div>
      </StyledMiaSection>
    </Layout>
  );
};

export default MiaPage;
